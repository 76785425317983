import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getSeatsSubmitApi } from "../../apis/search";
import PassengerDetails from "../../components/local/dashboard/PassengerDetails";
import Summary from "../../components/local/dashboard/Summary";
import scrollToTop from "../../utils/scrollToTop";

function CheckoutPage() {
  const [seatInformation, setSeatInformation] = useState([]);
  const { pre_booked_seat, schedule } = seatInformation;
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const state = location.state;

  useEffect(() => {
    let text = "";

    state?.seat_id.forEach((items) => {
      text += `&seat_id[]=${items}`;
    });
    const data = `?date=${state?.date}&from=${state?.from}&to=${state?.to}&schedule_id=${state?.schedule_id}${text}`;

    setIsLoading(true);
    getSeatsSubmitApi(data)
      .then((res) => {
        setSeatInformation(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [setSeatInformation, getSeatsSubmitApi]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="wrapper">
      <div className="confirmation-container">
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <Grid container spacinig={2}>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                <PassengerDetails pre_booked_seat={pre_booked_seat} schedule={schedule} />
              </Grid>
              <Grid item lg={6} md={12} sm={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Summary pre_booked_seat={pre_booked_seat} schedule={schedule} />
              </Grid>
            </Grid>
            {/* <PassengerDetails pre_booked_seat={pre_booked_seat} schedule={schedule} /> */}
            {/* <Summary pre_booked_seat={pre_booked_seat} schedule={schedule} /> */}
          </>
        )}
      </div>
    </div>
  );
}

export default CheckoutPage;
