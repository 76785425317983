import Protected from "../../components/global/protected";
import CheckoutPage from "../../pages/dashboard/CheckoutPage";
import ConfirmationPage from "../../pages/dashboard/ConfirmationPage";
import ProfilePage from "../../pages/dashboard/ProfilePage";
import TicketInfoPage from "../../pages/dashboard/TicketInfoPage";
import TicketPage from "../../pages/dashboard/TicketPage";
import PaymentFailed from "../../pages/PaymentFailed/PaymentFailed";

const DashboardRoutes = [
  {
    path: "/dashboard/profile",
    component: (
      <Protected>
        <ProfilePage />
      </Protected>
    )
  },
  {
    path: "/dashboard/ticket",
    component: (
      <Protected>
        <TicketPage />
      </Protected>
    )
  },
  {
    path: "/checkout",
    component: (
      <Protected>
        <CheckoutPage />
      </Protected>
    )
  },
  {
    path: "/confirmation",
    component: (
      <Protected>
        <ConfirmationPage />
      </Protected>
    )
  },
  {
    path: "/ticket-info/:bookingId",
    component: (
      <Protected>
        <TicketInfoPage />
      </Protected>
    )
  },
  {
    path: "/payment/failed/:bookingId",
    component: (
      <Protected>
        <PaymentFailed />
      </Protected>
    )
  }
];

export default DashboardRoutes;
