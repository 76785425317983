/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import Associations from '../components/local/associations';

const AssociationPage = () => {
  return (
    <Box>
      <Associations/>
    </Box>
  )
}

export default AssociationPage;
