import * as actions from "./types";

const initialState = {
  getStatus: "idle",
  updateStatus: "idle",
  passwordStatus: "idle",
  userData: []
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_DETAIL_BEGIN:
      return { ...state, getStatus: "loading" };
    case actions.GET_USER_DETAIL_SUCCESS:
      return { ...state, getStatus: "completed", userData: action.payload };
    case actions.GET_USER_DETAIL_ERROR:
      return { ...state, getStatus: "failed" };
    case actions.UPDATE_USER_DETAIL_BEGIN:
      return { ...state, updateStatus: "loading" };
    case actions.UPDATE_USER_DETAIL_SUCCESS:
      return { ...state, updateStatus: "completed" };
    case actions.UPDATE_USER_DETAIL_ERROR:
      return { ...state, updateStatus: "failed" };
    case actions.CHANGE_PASSWORD_BEGIN:
      return { ...state, passwordStatus: "loading" };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return { ...state, passwordStatus: "completed" };
    case actions.CHANGE_PASSWORD_ERROR:
      return { ...state, passwordStatus: "failed" };
    default:
      return state;
  }
};

export default profileReducer;
