import { axiosInstance } from "../../apis/_axios";

// login
export const loginApi = (data) => {
  return axiosInstance().post("/login", data);
};

// signup
export const forgotPasswordApi = (data) => {
  return axiosInstance().post("/reset-password-mail", data);
};

// reset password or verify email
export const resetPasswordApi = (data) => {
  return axiosInstance().post("/password-reset/change", data);
};

// verify email
export const verifyEmailApi = (data) => {
  return axiosInstance().post("/password-reset/change", data);
};

// register
export const registerApi = (data) => {
  return axiosInstance().post(`/register`, data);
};

// verification

export const userRegister = (data) => {
  return axiosInstance().post("/account-verification", data);
};

// reset password verify
export const verifyPasswordApi = (data) => {
  return axiosInstance().post("/api/reset-password", data);
};

export const socialLoginApi = (token, platform) => {
  return axiosInstance().get(`/api/login/${platform}/callback?token=${token}`);
};
