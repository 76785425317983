import React from "react";

function PassengerDetail({ label, labelValue }) {
  return (
    <div>
      <label className="form-label">{label}</label>
      <p className="detail-value">{labelValue}</p>
    </div>
  );
}

export default PassengerDetail;
