/* eslint-disable */
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { HookForm } from "../../form/HookForm";
import { HookFormInput } from "../../form/HookFormInput";
import { HookFormProvider } from "../../form/HookFormProvider";
import Loader from "../../global/loader/Loader";
import { postContactus } from "./redux/actions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  useEffect(() => {
    scrollTo(0, 0);
  }, [pathname]);

  const { contactus_loading } = useSelector((state) => state.contact);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Description is required"),
    email: yup.string().required("Email is required"),
    phone_no: yup.string().required("Phone is required")
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const refetch = () => {
    const array = ["name", "message", "subject", "email", "phone_no"];
    array?.map((item) => setValue(item, ""));
  };

  const onSubmit = (values) => {
    const data = {
      ...values
    };
    dispatch(postContactus(data, refetch));
  };

  return (
    <>
      <Box className="profile-container-wrapper">
        <h1>Contact Us</h1>
      </Box>
      <Box className="container">
        <Box className="contactFormContainer">
          <Paper elevation={0} className="contactFormPaperContainer">
            <Box className="contactFormWrap">
              <Box className="formHeading">
                <Typography className="formHead">Contact Us</Typography>
                <Typography className="formTitle">Please fill this form to continue.</Typography>
              </Box>
              <Box className="formSection">
                <HookFormProvider>
                  <HookForm onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="name"
                          // label="Name"
                          placeholder="Name"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="phone_no"
                          // label="Name"
                          placeholder="Phone"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="email"
                          name="email"
                          // label="Name"
                          placeholder="Email"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="subject"
                          // label="Name"
                          placeholder="Subject"
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <HookFormInput
                          required={true}
                          type="text"
                          name="message"
                          // label="Name"
                          placeholder="Say something you want to say"
                          control={control}
                          errors={errors}
                          multiline
                          rows={3}
                          sx={{
                            "& .css-17cvodc-MuiInputBase-input-MuiOutlinedInput-input": {
                              height: "82px"
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button className="formSubmitBtn" type="submit">
                          {contactus_loading ? <Loader /> : "Submit"}
                        </Button>
                      </Grid>
                    </Grid>
                  </HookForm>
                </HookFormProvider>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
