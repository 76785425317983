import DoneIcon from "@mui/icons-material/Done";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import AboutDescription from "../../global/description/AboutDescription";
// import busimg from "../../../assets/images/busimg.png";

const AssocationHead = ({ data }) => {
  return (
    <section className="section-page-wrapper">
      <div className="container">
        <Grid container>
          <Grid item lg={6} md={6} sm={12}>
            <Box className="associationImageBox">
              <img src={data?.photo} alt="" className="associationImage" />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Box className="busInfoContainer">
              <Typography className="busInfoMainText">
                <AboutDescription description={data?.about} />
              </Typography>
              <Box className="busInfoListBox">
                <DoneIcon fontSize="small" style={{ color: "#f2800f" }} />
                <Typography className="busInfoList"> Head Office: {data?.address}</Typography>
              </Box>
              <Box className="busInfoListBox">
                <DoneIcon fontSize="small" style={{ color: "#f2800f" }} />
                <Typography className="busInfoList">
                  {" "}
                  Contact Name: {data?.contact_person_name}
                </Typography>
              </Box>
              <Box className="busInfoListBox">
                <DoneIcon fontSize="small" style={{ color: "#f2800f" }} />
                <Typography className="busInfoList">
                  {" "}
                  Contact Number: {data?.contact_person_mobile_no}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <hr style={{ margin: "50px 0px", color: "#E5E5EB" }} /> */}
      </div>
    </section>
  );
};

export default AssocationHead;
