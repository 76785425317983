import * as actions from './Type';

export const seatCount = (data) => (dispatch) => {
  dispatch({ type: actions.SEAT_COUNT, payload: data });
};

export const seatIdentification = (data) => (dispatch) => {
  dispatch({ type: actions.SEAT_IDENTIFICATION, payload: data });
};

