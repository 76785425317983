import { postContactUsApi } from "../../../../apis/contactUs";
import * as actions from "../../../../components/local/contactUs/redux/types";
import { errorToast, successToast } from "../../../../utils/toast";

export const postContactus =
  (Data, refetch = () => {}) =>
  async (dispatch) => {
    dispatch({ type: actions.POST_CONTACT_US_BEGIN });

    postContactUsApi(Data)
      .then((res) => {
        refetch && refetch();
        dispatch({ type: actions.POST_CONTACT_US_SUCCESS });
        successToast(res.data.message);
      })
      .catch((error) => {
        dispatch({ type: actions.POST_CONTACT_US_ERROR });
        errorToast(error);
      });
  };
