import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import noTicketsIcon from "../../../assets/images/noTicketsIcon.svg";
import scrollToTop from "../../../utils/scrollToTop";
import SearchBar from "../../global/SearchBar";
import SearchTable from "./components/SearchTable";

function Search() {
  const SearchBusSelection = useSelector((state) => state.search);

  const { buses } = useSelector((state) => state.search);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="search-banner">
        <div className="search-banner-search">
          <SearchBar />
        </div>
      </div>

      <div className="container">
        <div className="search-page">
          {SearchBusSelection.bus_loading ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Spinner animation="border" />
            </div>
          ) : !buses?.data?.length ? (
            <div className="no-ticket-container">
              <div>
                <img src={noTicketsIcon} alt="no tickets available" />
              </div>
              <div>
                <p className="no-tickets-headText">No Buses Available</p>
              </div>
              <div>
                <p className="no-tickets-subText">
                  Choose another date for this route. Please search again.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="webHead">
                <div className="d-flex justify-content-between ">
                  <Grid container>
                    <Grid item lg={6} sm={12} xs={12} className="locationMobile">
                      <div className="title-wrapper">
                        <div className="page-title">
                          {SearchBusSelection?.buses?.data?.[0]?.route?.sub_route?.from?.city ||
                            SearchBusSelection?.buses?.data?.[0]?.route?.from?.city}{" "}
                          -{" "}
                          {SearchBusSelection?.buses?.data?.[0]?.route?.sub_route?.to?.city ||
                            SearchBusSelection?.buses?.data?.[0]?.route?.to?.city}
                        </div>
                        <div className="subtitle">
                          Available <span> {SearchBusSelection?.buses?.data?.length} Bus</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12} className="dateMobile">
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="page-date">
                          Date: <span>{SearchBusSelection?.buses?.data?.[0]?.departure_date}</span>
                        </div>
                        <div className="sort-by">
                          <span>Sort By:</span>
                          <select name="" id="">
                            <option value="">Available Seats</option>
                            <option value="">Price</option>
                          </select>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="mobileHead">
                <div className="d-flex justify-content-start ">
                  <Grid container>
                    <Grid item lg={6} sm={12} xs={12} className="locationMobile">
                      <div className="title-wrapper">
                        <div className="page-title">
                          {SearchBusSelection?.buses?.data?.[0]?.route?.sub_route?.from?.city ||
                            SearchBusSelection?.buses?.data?.[0]?.route?.from?.city}{" "}
                          -{" "}
                          {SearchBusSelection?.buses?.data?.[0]?.route?.sub_route?.to?.city ||
                            SearchBusSelection?.buses?.data?.[0]?.route?.to?.city}
                        </div>
                        <div className="subtitle">
                          Available <span> {SearchBusSelection?.buses?.data?.length} Bus</span>
                        </div>
                      </div>

                      <div className="" style={{ marginTop: "10px" }}>
                        <div className="page-date">
                          Date: <span>{SearchBusSelection?.buses?.data?.[0]?.departure_date}</span>
                        </div>
                        <div
                          className="sort-by"
                          style={{ marginLeft: "5px", paddingLeft: "10px", maxWidth: "60%" }}>
                          <span>Sort:</span>
                          <select name="" id="">
                            <option value="">Available Seats</option>
                            <option value="">Price</option>
                          </select>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <SearchTable />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Search;
