import style from "./style";

const Description = ({ description, length, styles }) => {
  const classes = style();
  const textStyles = {
    ...styles,
    fontSize: "12px !important",
    color: "red !important"
    //  backgroundColor: 'red'
  };

  return (
    <>
      <div
        style={{ ...textStyles }}
        className={`  ${length ? classes.lineClampDesc : classes.lineClamp}`}
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />
    </>
  );
};

export default Description;
