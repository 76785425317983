import { toast } from "react-toastify";
import {
  getBusSeatsApi,
  getLocationApi,
  getLocationMainApi,
  patchSeatsAfterTimeOutApi,
  payThroughKhaltiApi,
  postCompleteBookingApi,
  searchBusApi
} from "../../../../apis/search";
import { errorToast } from "../../../../utils/toast";
import * as actions from "./types";
// import axios from "axios";

// get location
export const getLocation = () => (dispatch) => {
  dispatch({ type: actions.GET_LOCATIONS_BEGIN });
  getLocationApi()
    .then((res) => {
      dispatch({ type: actions.GET_LOCATIONS_SUCCESS, payload: res.data.data.data });
    })
    .catch((err) => {
      dispatch({ type: actions.GET_LOCATIONS_ERROR });
      errorToast(err);
    });
};

// get location main
export const getLocationMain = () => (dispatch) => {
  dispatch({ type: actions.GET_LOCATIONS_MAIN_BEGIN });
  getLocationMainApi()
    .then((res) => {
      dispatch({ type: actions.GET_LOCATIONS_MAIN_SUCCESS, payload: res.data.data.data });
    })
    .catch((err) => {
      dispatch({ type: actions.GET_LOCATIONS_MAIN_ERROR });
      errorToast(err);
    });
};

// search buses
export const searchBus = (data, handleSuccess) => (dispatch) => {
  dispatch({ type: actions.SEARCH_BUS_BEGIN });
  searchBusApi(data)
    .then((res) => {
      dispatch({ type: actions.SEARCH_BUS_SUCCESS, payload: res.data.data });
      handleSuccess && handleSuccess();
    })
    .catch((err) => {
      dispatch({ type: actions.SEARCH_BUS_ERROR });
      errorToast(err);
    });
};

// get seats of specific Buses

export const getBusSeats = (data, handleSuccess) => (dispatch) => {
  dispatch({ type: actions.GET_SEATS_BEGIN });
  getBusSeatsApi(data)
    .then((res) => {
      dispatch({ type: actions.GET_SEATS_SUCCESS, payload: res.data });
      handleSuccess && handleSuccess();
    })
    .catch((err) => {
      dispatch({ type: actions.GET_SEATS_ERROR });
      errorToast(err);
    });
};

export const changeBookingStatus = (data) => (dispatch) => {
  dispatch({ type: actions.CHANGE_BOOKINGS_STATUS, payload: data });
};

// for changing status
export const changeStatusAfterTimeOut = (data) => (dispatch) => {
  patchSeatsAfterTimeOutApi(data)
    .then((res) => {
      const errors = {
        error: {
          response: res
        }
      };
      errorToast(errors);
    })
    .catch((err) => {
      errorToast(err);
    });
};

export const completeBooking = (data) => (dispatch) => {
  postCompleteBookingApi(data)
    .then((res) => {})
    .catch((err) => {
      errorToast(err);
    });
};

export const saveAllCustomerDetails =
  (data, getData = (data) => {}) =>
  async (dispatch) => {
    await postCompleteBookingApi(data)
      .then((res) => {
        getData(res.data);
      })
      .catch((err) => {
        errorToast(err);
      });
  };

export const payThroughKhalti = (data) => (dispatch) => {
  dispatch({ type: actions.SAVE_CUSTOMER_BEGIN });

  payThroughKhaltiApi(data)
    .then((response) => {
      window.location.href = response?.data?.data?.payment_url;
      dispatch({ type: actions.SAVE_CUSTOMER_SUCCESS });
    })
    .catch((error) => {
      dispatch({ type: actions.SAVE_CUSTOMER_ERROR });

      if (error.response?.data?.message?.detail) {
        toast.error(error.response?.data?.message?.detail, { theme: "colored" });
      } else {
        errorToast(error);
      }
    });
};

// export const getBookingHistory = (data) => (dispatch) => {
//   getBookingHistory()
//   .then(res => console.log("History booking is ",res))
// }

// export const setGlobalUser = () => (dispatch) => {
//   try {
//     const user = localStorage.getItem("USER");
//     const userObj = JSON.parse(user);
//     dispatch({
//       type: actions.SET_AUTH_USER,
//       payload: userObj
//     });
//   } catch (error) {
//     errorToast(error);
//   }
// };

// // login api
// export const login =
//   (data, setSignInLoading = null, goToDashboard = null) =>
//   (dispatch) => {
//     loginApi(data)
//       .then((res) => {
//         const token = res?.data?.data?.token;
//         const refreshToken = res?.data?.data?.refresh_token;
//         const user = res?.data?.data?.user;

//         // 2. setting token to the localStorage
//         localStorage.setItem("token", token);
//         localStorage.setItem("refreshToken", refreshToken);
//         localStorage.setItem("USER", JSON.stringify(user));
//         setSignInLoading && setSignInLoading(false);
//         successToast("Successfully Signed In");
//         dispatch(setGlobalUser());
//         goToDashboard && goToDashboard();
//       })
//       .catch((error) => {
//         errorToast(error);
//         setSignInLoading && setSignInLoading(false);
//       });
//   };

// export const logout = () => {
//   localStorage.removeItem("token");
//   localStorage.removeItem("refreshToken");
//   localStorage.removeItem("USER");
// };

// // send forgot password mail
// export const forgotPassword = (data, handleRedirect) => (dispatch) => {
//   dispatch({
//     type: actions.FORGOT_PASSWORD_BEGIN
//   });
//   forgotPasswordApi(data)
//     .then((res) => {
//       dispatch({
//         type: actions.FORGOT_PASSWORD_SUCCESS
//       });
//       successToast("Reset Link sent to your email.");
//       handleRedirect && handleRedirect();
//     })
//     .catch((error) => {
//       dispatch({
//         type: actions.FORGOT_PASSWORD_ERROR
//       });
//       errorToast(error);
//     });
// };

// // Set new Password with forgot password token or verify email
// export const resetPassword = (data, handleRedirect) => (dispatch) => {
//   dispatch({
//     type: actions.RESET_PASSWORD_BEGIN
//   });
//   resetPasswordApi(data)
//     .then((res) => {
//       dispatch({
//         type: actions.RESET_PASSWORD_SUCCESS
//       });
//       handleRedirect && handleRedirect();
//       successToast("Password created Successfully");
//     })
//     .catch((error) => {
//       dispatch({
//         type: actions.RESET_PASSWORD_ERROR
//       });
//       errorToast(error);
//     });
// };
