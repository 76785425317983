import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEdit, FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useYupValidationResolver from "../../../../hooks/useYupValidationResolver";
import FilledButton from "../../../form/CustomButton/FilledButton";
import CustomInput from "../../../form/CustomInput/CustomInput";
import SectionTitle from "./SectionTitle";
import { getUserDetail, updateUserDetail } from "./redux/actions";

function ProfileForm() {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const { updateStatus } = useSelector((state) => state.profile);
  const [image, setImage] = useState();
  const imageRef = useRef();

  const schema = yup.object({
    first_name: yup.string().required("First name field is required"),
    last_name: yup.string().required("Last name field is required"),
    email: yup.string().email(),
    phone: yup.number().required("Mobile number field is required")
  });

  const resolver = useYupValidationResolver(schema);

  console.log({ profileState });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver
  });

  useEffect(() => {
    if (profileState.getStatus === "idle") {
      dispatch(getUserDetail());
    }
    setValue("first_name", profileState?.userData?.first_name);
    setValue("last_name", profileState?.userData?.last_name);
    setValue("email", profileState?.userData?.email);
    setValue("phone", profileState?.userData?.phone);
    // setValue("phone", profileState?.userData?.image);
  }, [profileState.getStatus, profileState.passwordStatus]);

  const refetch = () => {
    dispatch(getUserDetail());
  };

  function handleUserUpdate(data) {
    dispatch(updateUserDetail({ ...data, image }, refetch));
  }

  return (
    <div className="profile-form-container">
      <SectionTitle
        title={"Personal Details"}
        component={
          <FiUser color="#065F46" size={"16px"} fontWeight={700} className="profile-icons" />
        }
      />
      <form encType="multipart/form-data" onSubmit={handleSubmit(handleUserUpdate)}>
        <Box className="profile-avatar">
          <Avatar
            src={(image && URL.createObjectURL(image)) ?? profileState?.userData?.image}
            variant="square"
            style={{
              width: "150px",
              height: "150px",
              marginTop: "10px"
            }}
          />
          <Box
            style={{
              position: "absolute",
              // right: "-10px",
              bottom: "0px"
            }}>
            <Button
              startIcon={<FiEdit />}
              style={{
                backgroundColor: "#434343",
                color: "#fff",
                width: "150px"
              }}
              onClick={() => imageRef.current.click()}>
              Upload
            </Button>
          </Box>
        </Box>
        <Box marginTop={"10px"} display={"none"}>
          <input
            type="file"
            onChange={(e) => setImage(e.target.files && e.target.files?.[0])}
            ref={imageRef}
          />
        </Box>

        <CustomInput
          control={control}
          name={"first_name"}
          label={"First Name"}
          placeholder={"Enter Your First Name"}
          errors={errors}
        />

        <CustomInput
          control={control}
          name={"last_name"}
          label={"Last Name"}
          placeholder={"Enter Your Last Name"}
          errors={errors}
        />

        <CustomInput
          control={control}
          name={"email"}
          label={"Email"}
          placeholder={"Enter Email"}
          errors={errors}
          disabled
        />

        <CustomInput
          control={control}
          name={"phone"}
          label={"Mobile Number"}
          placeholder={"Enter Mobile Number"}
          type={"number"}
          errors={errors}
        />

        <div className="btn-wrapper">
          <FilledButton
            btnLabel={
              updateStatus === "loading" ? (
                <CircularProgress
                  size="27px"
                  style={{ color: "#fff", display: "flex", alignItems: "center", margin: "auto" }}
                />
              ) : (
                "Update"
              )
            }
          />
        </div>
      </form>
    </div>
  );
}

export default ProfileForm;
