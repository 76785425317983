import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { postTransactionKhaltiFailedApi } from "../../apis/payment";

function PaymentFailed() {
  const location = useLocation();
  const { bookingId } = useParams();

  useEffect(() => {
    if (bookingId) {
      const finalData = location.search.replace("?", "").split("=");

      postTransactionKhaltiFailedApi({ booking_id: bookingId })
        .then((res) => {
          console.log("Res in res is ==> ", res);
        })
        .catch((err) => {
          console.log("Err is ", err);
        });

      /* postTransactionEsewaFailedApi({ booking_id: finalData[1] })
        .then((res) => {
          console.log("Res in res is ==> ", res);
        })
        .catch((err) => {
          console.log("Err is ", err);
        }); */

      // axios.post('http://192.168.1.66:8000/api/payment/esewa/fail', {booking_id : finalData[1]})
      // .then(res => {
      //   console.log("Res in res is ==> ",res)
      // })
      // .catch(err => {
      //   console.log("Err is ",err)
      // })
    }
  }, []);

  return (
    <div>
      <div className="wrapper">
        <div className="ticket-container">
          <div className="ticket-success-container ">
            <h3 className="errors-text">Sorry, Transaction has failed. Please Try Again Later.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailed;
