export const GET_HERO_BANNER_BEGIN = "GET_HERO_BANNER_BEGIN";
export const GET_HERO_BANNER_SUCCESS = "GET_HERO_BANNER_SUCCESS";
export const GET_HERO_BANNER_ERROR = "GET_HERO_BANNER_ERROR";

export const GET_ASSOCIATION_BEGIN = "GET_ASSOCIATION_BEGIN";
export const GET_ASSOCIATION_SUCCESS = "GET_ASSOCIATION_SUCCESS";
export const GET_ASSOCIATION_ERROR = "GET_ASSOCIATION_ERROR";

export const GET_SINGLE_ASSOCIATION_BEGIN = "GET_SINGLE_ASSOCIATION_BEGIN";
export const GET_SINGLE_ASSOCIATION_SUCCESS = "GET_SINGLE_ASSOCIATION_SUCCESS";
export const GET_SINGLE_ASSOCIATION_ERROR = "GET_SINGLE_ASSOCIATION_ERROR";

export const GET_SERVICES_BEGIN = "GET_SERVICES_BEGIN";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_ERROR = "GET_SERVICES_ERROR";

export const GET_SINGLE_SERVICES_BEGIN = "GET_SINGLE_SERVICES_BEGIN";
export const GET_SINGLE_SERVICES_SUCCESS = "GET_SINGLE_SERVICES_SUCCESS";
export const GET_SINGLE_SERVICES_ERROR = "GET_SINGLE_SERVICES_ERROR";
