import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import React from "react";
import { Controller } from "react-hook-form";
import { GoCalendar } from "react-icons/go";

function CustomNepaliDatePickerHistory({
  control,
  label = "",
  placeholder = "",
  type = "text",
  name,
  disabled = false,
  require,
  errors,
  isStyled = false,
  isIcon = false,
  minDate,
  defaultDate
}) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div className="date-picker-parent">
            <Calendar
              inputClassName="form-control"
              className={isStyled ? "date-picker" : ""}
              onChange={(value) => onChange(value?.bsDate)}
              // minDate={newDate}
              language="en"
            />

            {isIcon && (
              <div className="date-picker-icon">
                <GoCalendar size={"18px"} color={"#6C6B80"} />
              </div>
            )}
          </div>
        )}
      />
      {errors && errors[name] && <small className="error-text">{errors?.[name]?.message}</small>}
    </>
  );
}

export default CustomNepaliDatePickerHistory;
