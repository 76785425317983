import React from "react";
import Profile from "../../components/local/dashboard/Profile/Profile";

function ProfilePage() {
  return (
    <>
      <div className="profile-container-wrapper">
        <h1>My Profile</h1>
      </div>

      <Profile />
    </>
  );
}

export default ProfilePage;
