import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Container } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SearchBar from "../../../components/global/SearchBar";
import BannerSection from "./BannerSection";
import { getHeroBanner } from "./redux/actions";

function Banner() {
  const dispatch = useDispatch();
  const { heroBanner, heroBanner_loading } = useSelector((state) => state.home);
  const [heroBannerData, setHeroBannerData] = useState([]);

  useEffect(() => {
    heroBanner?.data && setHeroBannerData(heroBanner?.data);
  }, [heroBanner?.data]);

  useEffect(() => {
    dispatch(getHeroBanner());
  }, []);

  const sliderRef = useRef();

  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowRightIcon
          className="arrowIconRight"
          style={{
            ...style,
            display: "none"
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <KeyboardArrowLeftIcon
          className="arrowIconLeft"
          style={{
            ...style,
            display: "none"
          }}
          onClick={onClick}
        />
      </Container>
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: teamsData?.length >= 3 ? 3 : teamsData?.length,
          // slidesToShow: 3,
          // slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          // slidesToShow: teamsData?.length >= 2 ? 2 : teamsData?.length,
          // slidesToShow: 2,
          // slidesToScroll: 1,
          // prevArrow: false,
          // nextArrow: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          // slidesToShow: teamsData?.length >= 1 ? 1 : teamsData?.length,
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // prevArrow: false,
          // nextArrow: false
        }
      }
    ]
  };

  return (
    <div className="banner-section">
      {heroBanner_loading ? (
        <div style={{ height: "410px" }}></div>
      ) : (
        <Slider {...settings} ref={sliderRef}>
          {heroBannerData?.map((item) => {
            return <BannerSection key={item?.id} data={item} />;
          })}
        </Slider>
      )}
      <div className="banner-search">
        <SearchBar />
      </div>
    </div>
  );
}
export default Banner;
