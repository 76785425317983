import style from "../../../components/global/description/style"

const TermsAndConditionsDesc = ({ description }) => {
  const classes = style();
  const styles = {
    fontSize: "14px !important",
    color: "blue !important",
    //  backgroundColor: 'red'
  };

  return (
    <>
      <div
        style={{ ...styles }}
        //   className={`${length ? classes.lineClampDesc : classes.lineClamp}`}
        className={classes.lineClampDescTerms}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </>
  );
};

export default TermsAndConditionsDesc;
