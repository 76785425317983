import React from "react";

function SectionTitle({ title, component }) {
  return (
    <div className="profile-section-title">
      <h3>
        {component} {title}
      </h3>
    </div>
  );
}

export default SectionTitle;
