import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useYupValidationResolver from "../../../hooks/useYupValidationResolver";
import CustomInput from "../../form/CustomInput/CustomInput";
import { register, socialLogin } from "./redux/actions";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleButton } from "./SocialLoginButton";
import { FacebookButton } from "./SocialLoginButton";

function Register({ handleOtherClose, handleClose }) {
  const dispatch = useDispatch();
  const { siteSettingData } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);

  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);
  const { registration_loading } = useSelector((state) => state.auth);
  const defaultValues = {
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    password_confirmation: "",
    email: ""
  };
  const schema = yup.object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    phone: yup
      .string()
      .typeError("It must be a number")
      .required("Phone number is required")
      .min(10)
      .max(10),
    email: yup.string().email().required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Confirm Password don't match")
  });

  const resolver = useYupValidationResolver(schema);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues, resolver });
  const submit = (values) => {
    dispatch(register(values, handleClose));
  };

  const handleLogin = () => {
    handleClose();
    handleOtherClose();
  };

  const handleSocialLogin = (response, platform) => {
    handleClose();
    const accessToken =
      platform === "google"
        ? response.access_token
        : platform === "facebook"
        ? response.accessToken
        : undefined;
    dispatch(socialLogin(accessToken, platform, handleClose));
  };

  const handleSocialLoginError = (error) => {
    handleClose();
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => handleSocialLogin(codeResponse, "google"),
    onError: (error) => handleSocialLoginError(error)
  });

  return (
    <div className="login-section">
      <div className="image-container">
        <div className="img-wrapper">
          <img src={siteSettingInfo?.logo} alt="Logo" />
        </div>
        <div className="main-title">Sign Up</div>
        <form onSubmit={handleSubmit(submit)}>
          <div className="mb-2 input-container">
            <CustomInput control={control} name="first_name" label="First Name" errors={errors} />
            <CustomInput control={control} name="last_name" label="Last Name" errors={errors} />
          </div>
          <div className="mb-2 input-container">
            <CustomInput control={control} name="phone" label="Mobile Number" errors={errors} />
            <CustomInput control={control} name="email" label="Email" errors={errors} />
          </div>
          <div className="mb-2 input-container">
            <CustomInput
              control={control}
              name="password"
              type="password"
              label="Password"
              errors={errors}
            />
            <CustomInput
              control={control}
              name="password_confirmation"
              type="password"
              label="Password Confirmation"
              errors={errors}
            />
          </div>
          {!registration_loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ width: "100%" }} type="submit">
                Create Account
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ width: "100%" }} disabled={true}>
                <div className="spinner-border spinner-border-sm text-white" role="status"></div>{" "}
                Creating...
              </button>
            </div>
          )}

          <div className="divider">
            <div></div>
            <p>Or continue with</p>
            <div></div>
          </div>

          <GoogleButton googleLoginHandler={googleLoginHandler} />
          <FacebookButton handleSocialLogin={handleSocialLogin} />

          <div className="last-line">
            Already have an account? <span onClick={handleLogin}>Login</span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
