import * as actions from "./types";

const initialState = {
  heroBanner: {},
  heroBanner_loading: false,
  association: [],
  association_loading: false,
  association_single: [],
  association_single_loading: false,
  services: [],
  services_loading: false,
  services_single: [],
  services_single_loading: false
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HERO_BANNER_BEGIN:
      return { ...state, heroBanner_loading: true };

    case actions.GET_HERO_BANNER_SUCCESS:
      return {
        ...state,
        heroBanner_loading: false,
        heroBanner: action.payload
      };

    case actions.GET_HERO_BANNER_ERROR:
      return { ...state, heroBanner_loading: false };

    //  association

    case actions.GET_ASSOCIATION_BEGIN:
      return { ...state, association_loading: true };

    case actions.GET_ASSOCIATION_SUCCESS:
      return { ...state, association_loading: false, association: action.payload };

    case actions.GET_ASSOCIATION_ERROR:
      return { ...state, association_loading: false };

    case actions.GET_SINGLE_ASSOCIATION_BEGIN:
      return { ...state, association_single_loading: true };

    case actions.GET_SINGLE_ASSOCIATION_SUCCESS:
      return { ...state, association_single_loading: false, association_single: action.payload };

    case actions.GET_SINGLE_ASSOCIATION_ERROR:
      return { ...state, association_single_loading: false };
    //  services

    case actions.GET_SERVICES_BEGIN:
      return { ...state, services_loading: true };

    case actions.GET_SERVICES_SUCCESS:
      return { ...state, services_loading: false, services: action.payload };

    case actions.GET_SERVICES_ERROR:
      return { ...state, services_loading: false };

    case actions.GET_SINGLE_SERVICES_BEGIN:
      return { ...state, services_single_loading: true };

    case actions.GET_SINGLE_SERVICES_SUCCESS:
      return { ...state, services_single_loading: false, services_single: action.payload };

    case actions.GET_SINGLE_SERVICES_ERROR:
      return { ...state, services_single_loading: false };
    default:
      return state;
  }
};

export default homeReducer;
