import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useYupValidationResolver from "../../../hooks/useYupValidationResolver";
import CustomInput from "../../form/CustomInput/CustomInput";
import { forgotPassword } from "./redux/actions";

function ForgotPassword({ handleOtherClose, handleClose }) {
  const dispatch = useDispatch();
  const { siteSettingData } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);
  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);

  const { password_loading } = useSelector((state) => state.auth);
  const defaultValues = {
    email: ""
  };

  const schema = yup.object({
    email: yup.string().email().required("Email is required")
  });

  const resolver = useYupValidationResolver(schema);
  const { control, handleSubmit } = useForm({ defaultValues, resolver });
  const submit = (values) => {
    dispatch(forgotPassword(values, handleClose));
  };
  const handleLogin = () => {
    handleClose();
    handleOtherClose();
  };
  return (
    <div className="login-section">
      <div className="image-container">
        <div className="img-wrapper">
          <img src={siteSettingInfo?.logo} alt="Logo" />
        </div>
        <div className="main-title">Reset Password</div>
        <form onSubmit={handleSubmit(submit)}>
          <div className="mb-2">
            <CustomInput control={control} name="email" label="Email" />
          </div>

          {!password_loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button type="submit">Send Reset Link</button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button disabled={true}>
                <div className="spinner-border spinner-border-sm text-white" role="status"></div>{" "}
                Sending...
              </button>
            </div>
          )}
          <div className="last-line">
            Already have an account? <span onClick={handleLogin}>Login</span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
