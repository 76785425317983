import React from "react";
import { Link } from "react-router-dom";

function FilledButton({ btnLabel, linkPath = "#", state = "", type = "" }) {
  if (linkPath !== "#") {
    return (
      <Link to={linkPath} state={state}>
        <button className="filled-btn" type={type}>
          {btnLabel}
        </button>
      </Link>
    );
  }

  return (
    <button className="filled-btn" type={type}>
      {btnLabel}
    </button>
  );
}

export default FilledButton;
