export const GET_USER_DETAIL_BEGIN = "GET_USER_DETAIL_BEGIN";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR";

export const UPDATE_USER_DETAIL_BEGIN = "UPDATE_USER_DETAIL_BEGIN";
export const UPDATE_USER_DETAIL_SUCCESS = "UPDATE_USER_DETAIL_SUCCESS";
export const UPDATE_USER_DETAIL_ERROR = "UPDATE_USER_DETAIL_ERROR";

export const CHANGE_PASSWORD_BEGIN = "CHANGE_PASSWORD_BEGIN";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
