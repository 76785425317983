import React from "react";
// import BusImageOne from "../../../assets/images/Bus.jpg";
// import BusImageTwo from "../../../assets/images/Bus2.jpg";
// import BusImageThree from "../../../assets/images/Bus3.jpg";
import Banner from "./Banner";
import Card from "./Card";
import SectionTitle from "./SectionTitle";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container, Grid, Skeleton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import BrandOne from "../../../assets/images/brand1.png";
// import BrandTwo from "../../../assets/images/brand2.png";
// import BrandThree from "../../../assets/images/brand3.png";
// import AssociationMarqee from "./AssociationMarqee";
// import Banner from "./Banner";
import Destination from "./Destination";
import { getAssociation, getServices } from "./redux/actions";

function Home() {
  const dispatch = useDispatch();
  // get association
  const { association, association_loading } = useSelector((state) => state.home);
  const [associationData, setAssociationData] = useState([]);

  useEffect(() => {
    association?.data && setAssociationData(association?.data);
  }, [association?.data]);

  useEffect(() => {
    dispatch(getAssociation());
  }, []);

  // get services

  const { services, services_loading } = useSelector((state) => state.home);
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    services?.data && setServicesData(services?.data);
  }, [services?.data]);

  useEffect(() => {
    dispatch(getServices());
  }, []);

  // data for cards
  // const cardData = [
  //   {
  //     id: 1,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageOne,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 2,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageTwo,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 3,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageThree,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 4,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageTwo,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 5,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageThree,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 6,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageOne,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 7,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageTwo,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   },
  //   {
  //     id: 8,
  //     title: "Dhading Bus",
  //     type: "Normal Bus",
  //     image: BusImageThree,
  //     description: "Wifi ,Fan System,Music System,Comfortable Seats,Mineral Water,Mobile Charger"
  //   }
  // ];

  const destinations = ["kakarvitta", "Nawalparasi", "Dhading", "Kavre", "Dhulikhel"];

  function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <ArrowBackIcon
          fontSize="large"
          className="assoArrowIconLeft"
          style={{
            ...style,
            color: "#262626",
            padding: "4px"
          }}
          onClick={onClick}
        />
      </Container>
    );
  }
  function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
      <Container maxWidth="lg">
        <ArrowForwardIcon
          fontSize="large"
          className="assoArrowIconRight"
          style={{
            ...style,
            color: "#262626",
            padding: "4px"
          }}
          onClick={onClick}
        />
      </Container>
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: associationData?.length >= 4 ? 4 : associationData?.length,
    // slidesToShow: 4,
    // slidesToShow: numItems,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: associationData?.length >= 3 ? 3 : associationData?.length,
          // slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: associationData?.length >= 2 ? 2 : associationData?.length,
          // slidesToShow: 2,
          slidesToScroll: 1,
          // prevArrow: false,
          // nextArrow: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: associationData?.length >= 1 ? 1 : associationData?.length,
          // slidesToShow: 1,
          slidesToScroll: 1,
          // prevArrow: false,
          // nextArrow: false
          arrows: false
        }
      }
    ]
  };

  const sliderAccRef = useRef();

  return (
    <>
      <Banner />
      {/* <Hero/> */}
      {/* bus service card section  */}
      <section className="section-wrapper">
        <div className="container">
          <div style={{ margin: "20px" }}>
            <SectionTitle title={"Our Services"} />
          </div>
          <div className="">
            <Grid container spacing={2} className="">
              {services_loading ? (
                <div className="container">
                  <Skeleton variant="rectangular" width="100%" height={300} />
                </div>
              ) : (
                // <div className="section-grid">
                <>
                  {/* cards */}
                  {servicesData?.data?.slice(0, 6)?.map((card) => (
                    <Card key={card.id} data={card} />
                  ))}
                </>
              )}
            </Grid>
          </div>
        </div>
      </section>

      {/* <section className="slider-section-wrapper">
        <div style={{ marginBottom: "60px" }}>
          <SectionTitle title={"Our Bus Associations"} />
        </div>

        {association_loading ? (
          <div className="container">
            <Skeleton variant="rectangular" width="100%" height={300} />
          </div>
        ) : (
          <div className="container">
            <Slider {...settings} ref={sliderAccRef}>
              {associationData?.map((card) => (
                <>
                  <Box sx={{ padding: "0px 10px" }}>
                    <CardSlider key={card.id} data={card} slider />
                  </Box>
                </>
              ))}
            </Slider>
          </div>
        )}
      </section> */}

      {/* our popular destination */}

      <section>
        <Destination destinations={destinations} />
      </section>

      {/* our associations */}
      {/* <section className="association-wrapper">
        <SectionTitle title={"Our Associations"} />
        <AssociationMarqee components={associationData} />
      </section> */}
    </>
  );
}

export default Home;
