/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Available from "../../../../assets/images/available-seat.png";
import Booked from "../../../../assets/images/booked-seat.png";
import Reserved from "../../../../assets/images/reserved-seat.svg";
import Selected from "../../../../assets/images/selected-seat.png";
import Driver from "../../../../assets/images/stering.png";
import Unavailable from "../../../../assets/images/unavailable-seat.png";

function BusSeats(props) {
  const { userData } = useSelector((state) => state.profile);

  const seatsInformation = useSelector((state) => state.search?.seats);
  const [xsorting, setxsorting] = useState();
  const [ysorting, setysorting] = useState();
  const [seatsData, setSeatsData] = useState(seatsInformation?.data);

  useEffect(() => {
    setSeatsData(seatsInformation?.data);
    if (seatsInformation?.data) {
      setysorting(seatsInformation?.data?.sort((a, b) => a.grid_y - b.grid_y));
      setxsorting(seatsInformation?.data?.sort((a, b) => a.grid_x - b.grid_x));
    }
  }, [seatsInformation?.data]);

  const xmin = Math.min(...seatsInformation?.data?.map((list) => list.grid_x));
  const xmax = Math.max(...seatsInformation?.data?.map((list) => list.grid_x));
  const ymin = Math.min(...seatsInformation?.data?.map((list) => list.grid_y));
  const ymax = Math.max(...seatsInformation?.data?.map((list) => list.grid_y));

  const ToggleBooking = (foundData) => {
    const data = seatsData?.find((el) => el.seat_id === foundData.seat_id);
    const filtered = seatsData?.filter((el) => el.seat_id !== foundData.seat_id);

    if (data.seat_status === "Available") {
      data.seat_status = "isBooking";

      props.setCount((prev) => Number(prev) + 1);
      props.setSeats((prev) => [...(prev || []), foundData.seat_id]);
      props.setSeatsName((prev) => [...(prev || []), foundData.seat_name]);
    } else {
      data.seat_status = "Available";
      props.setCount((prev) => Number(prev) - 1);
      props.setSeats((prev) =>
        prev.filter((p) => {
          return p !== foundData.seat_id;
        })
      );
      props.setSeatsName((prev) =>
        prev.filter((p) => {
          return p !== foundData.seat_name;
        })
      );
    }
    setSeatsData([...filtered, data]);
  };

  return (
    <div className="bus-seat-section">
      <div className="bus-seat-wrapper">
        <div className="driver">
          <img src={Driver} alt="Driver Icon" />
          <img src={Unavailable} alt="Unavailable" />
        </div>
        <div className="bus-seat-area">
          {[...Array(xmax).keys()]?.map((index) => {
            let isShown = false;
            if (index + 1 >= xmin && index + 1 <= xmax) {
              return (
                <div className="seat-row" key={index}>
                  {[...Array(ymax).keys()]?.map((list) => {
                    const gridYLocal = list + 1;
                    const gridXLocal = index + 1;
                    const foundData = seatsData?.find((el) => {
                      return el?.grid_x === gridXLocal && el?.grid_y === gridYLocal;
                    });
                    if (isShown === false) {
                      if (foundData) {
                        isShown = true;
                      }
                    }
                    if (list + 1 >= ymin && list + 1 <= ymax) {
                      return (
                        <React.Fragment key={list}>
                          {console.log("qqqqqww", foundData?.user?.id)}
                          <div
                            className="seat-holder"
                            onClick={() => {
                              if (
                                foundData?.available_to === "Offline" ||
                                foundData?.available_to === "System" ||
                                foundData?.available_to === "DisableAdminSamiti" ||
                                foundData?.available_to === "" ||
                                foundData.seat_status === "Disabled" ||
                                foundData.seat_status === "Blocked" ||
                                // foundData.seat_status === "Booking" ||
                                (foundData.seat_status === "Booking" &&
                                  foundData?.user_id !== userData?.id) ||
                                foundData?.seat_status === "Booked"
                              ) {
                                return;
                              }
                              ToggleBooking(foundData);
                            }}>
                            {!foundData && <div className="empty-seat"></div>}
                            {foundData?.seat_status === "isBooking" && (
                              <img src={Selected} alt={foundData?.seat_status} />
                            )}

                            {foundData?.seat_status === "Available" &&
                              (foundData?.available_to === "Online" ||
                                foundData?.available_to === "Both") && (
                                <img src={Available} alt={foundData?.seat_status} />
                              )}

                            {(foundData?.seat_status === "Disabled" ||
                              foundData?.available_to === "" ||
                              foundData?.available_to === "Offline" ||
                              foundData?.seat_status === "Blocked" ||
                              (foundData?.available_to === "System" &&
                                foundData?.seat_status !== "Booked")) && (
                              <img src={Unavailable} alt={foundData?.seat_status} />
                            )}
                            {foundData?.seat_status === "Booked" && (
                              <img
                                src={Booked}
                                alt={foundData?.seat_status}
                                style={{ cursor: "default" }}
                              />
                            )}
                            {foundData?.seat_status === "Booking" &&
                              foundData?.user_id === userData?.id &&
                              (foundData?.available_to === "Online" ||
                                foundData?.available_to === "Both") && (
                                <img
                                  src={Selected}
                                  alt={foundData?.seat_status}
                                  style={{ cursor: "default" }}
                                />
                              )}
                            {foundData?.seat_status === "Available" &&
                              !(
                                foundData?.available_to === "Online" ||
                                foundData?.available_to === "System" ||
                                foundData?.available_to === "Both"
                              ) && <img src={Unavailable} alt={foundData?.seat_status} />}

                            <div className="seat-no">{foundData?.seat_name}</div>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <ul className="bus-seat-index">
        <li>
          <img src={Available} alt="Available" />
          <div className="title">Available</div>
        </li>
        <li>
          <img src={Selected} alt="Selected" />
          <div className="title">Selected</div>
        </li>
        <li>
          <img src={Booked} alt="Booked" />
          <div className="title">Booked</div>
        </li>
        <li>
          <img src={Reserved} alt="Reserved" />
          <div className="title">Reserved</div>
        </li>
        <li>
          <img src={Unavailable} alt="Unavailable" />
          <div className="title">Unavailable</div>
        </li>
      </ul>
    </div>
  );
}

export default BusSeats;
