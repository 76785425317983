import * as actions from "./types";

const defaultState = {
  locations: [],
  location_loading: false,
  locations_main: [],
  location_main_loading: false,
  buses: [],
  bus_loading: false,
  seats: null,
  seat_loading: false,
  select_seat_loading: false,
  customer_loading: false,
  booked_seats: {
    schedule_id: 0,
    route_id: null,
    drop_location: null,
    rate: null,
    qty: 0,
    discount: "",
    total: null,
    contact_name: null,
    contact_phone: null,
    paid: null,
    due: null,
    return: null,
    bus_details: null,
    seat_id: [],
    sub_route_price: ""
  }
};

const searchReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.GET_LOCATIONS_BEGIN:
      return { ...state, location_loading: true };
    case actions.GET_LOCATIONS_SUCCESS:
      return { ...state, location_loading: false, locations: action.payload };
    case actions.GET_LOCATIONS_ERROR:
      return { ...state, location_loading: false };

    case actions.GET_LOCATIONS_MAIN_BEGIN:
      return { ...state, location_main_loading: true };
    case actions.GET_LOCATIONS_MAIN_SUCCESS:
      return { ...state, location_main_loading: false, locations_main: action.payload };
    case actions.GET_LOCATIONS_MAIN_ERROR:
      return { ...state, location_main_loading: false };

    case actions.SEARCH_BUS_BEGIN:
      return { ...state, bus_loading: true };
    case actions.SEARCH_BUS_SUCCESS:
      return { ...state, bus_loading: false, buses: action.payload };
    case actions.SEARCH_BUS_ERROR:
      return { ...state, bus_loading: false };

    case actions.GET_SEATS_BEGIN:
      return { ...state, seat_loading: true };

    case actions.GET_SEATS_SUCCESS:
      return { ...state, seat_loading: false, seats: action.payload };
    case actions.GET_SEATS_ERROR:
      return { ...state, seat_loading: false };

    case actions.SELECT_SEAT_BEGIN:
      return { ...state, select_seat_loading: true };

    case actions.SELECT_SEAT_ERROR:
    case actions.SELECT_SEAT_SUCCESS:
      return { ...state, select_seat_loading: false };

    // proceding to success data passed.....
    case actions.POST_SEAT_BEGIN:
      return { ...state, seat_loading: true };

    case actions.POST_SEAT_SUCCESS:
      return { ...state, seat_loading: false };
    case actions.POST_SEAT_ERROR:
      return { ...state, seat_loading: false };

    case actions.SAVE_CUSTOMER_BEGIN:
      return { ...state, customer_loading: true };

    case actions.SAVE_CUSTOMER_SUCCESS:
      return { ...state, customer_loading: false };
    case actions.SAVE_CUSTOMER_ERROR:
      return { ...state, customer_loading: false };

    default:
      return state;
  }
};

export default searchReducer;
