/*eslint-disable*/
import { Box } from '@mui/material';
import React from 'react';
import WhyBusSewa from '../components/local/whyBusSewa';

const WhyBusSewaPage = () => {
  return (
    <Box>
      <WhyBusSewa/>
    </Box>
  )
}

export default WhyBusSewaPage;
