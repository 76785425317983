import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonAboutImage({ asso }) {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}

      {asso ? (
        <Skeleton variant="rounded" width="80%" height={300} />
      ) : (
        <Skeleton variant="rounded" width={400} height={450} />
      )}

      {/* <Skeleton variant="text" width={150} />
      <Skeleton variant="text" width={270} />
      <br />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" /> */}

      {/* For other variants, adjust the size with `width` and `height` */}
      {/* <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={210} height={60} /> */}
    </Stack>
  );
}
