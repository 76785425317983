import * as actions from "./types";

const defaultState = {
  isLoading: true,
  responseData: {}
};

const paymentReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.POST_ESEWA_VERIFY_BEGIN:
      return { ...state, isLoading: true };

    case actions.POST_ESEWA_VERIFY_SUCCESS:
      return { ...state, isLoading: false, responseData: action.payload.data };
    case actions.POST_ESEWA_VERIFY_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default paymentReducer;
