import {
  getAssociationApi,
  getAssociationSingleApi,
  getHeroBannerApi,
  getServicesApi,
  getServicesSingleApi
} from "../../../../apis";
import * as actions from "./types";

export const getHeroBanner = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_HERO_BANNER_BEGIN });

  try {
    const { data } = await getHeroBannerApi(Data);
    dispatch({
      type: actions.GET_HERO_BANNER_SUCCESS,
      payload: data?.data
    });
  } catch (error) {
    dispatch({ type: actions.GET_HERO_BANNER_ERROR });
  }
};

// get association

export const getAssociation = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_ASSOCIATION_BEGIN });

  try {
    const { data } = await getAssociationApi(Data);
    dispatch({ type: actions.GET_ASSOCIATION_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({ type: actions.GET_ASSOCIATION_ERROR });
  }
};

export const getAssociationSingle = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_SINGLE_ASSOCIATION_BEGIN });

  try {
    const { data } = await getAssociationSingleApi(Data);
    dispatch({ type: actions.GET_SINGLE_ASSOCIATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: actions.GET_SINGLE_ASSOCIATION_ERROR });
  }
};
// get services

export const getServices = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_SERVICES_BEGIN });

  try {
    const { data } = await getServicesApi(Data);
    dispatch({ type: actions.GET_SERVICES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: actions.GET_SERVICES_ERROR });
  }
};

export const getServicesSingle = (Data) => async (dispatch) => {
  dispatch({ type: actions.GET_SINGLE_SERVICES_BEGIN });

  try {
    const { data } = await getServicesSingleApi(Data);
    dispatch({ type: actions.GET_SINGLE_SERVICES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: actions.GET_SINGLE_SERVICES_ERROR });
  }
};
