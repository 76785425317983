// import moment from "moment";
import React from "react";
import { print } from "react-html2pdf";
import { HiDownload } from "react-icons/hi";
import TimeFormat from "../../../global/timeFormat";

function TicketShowcase({
  showCaseTitle,
  from,
  to,
  date,
  time,
  seats,
  travelName,
  route,
  name,
  phoneNo,
  status
}) {
  return (
    //   refactor needed
    <div className="showcase-container">
      <div>
        <div id="printDiv" className="ticket-wrapper">
          <h3 className="show-case-title">{showCaseTitle}</h3>
          <div className="ticket-container">
            <div className="destination-container">
              <p>From</p>
              <h4>{from}</h4>
            </div>
            <div className="destination-container">
              <p>To</p>
              <h4>{to}</h4>
            </div>
          </div>

          <hr />
          <div className="ticketdata-container">
            <h4>Date</h4>
            {/* <p className="ticket-data-bold">{moment(date).format("YYYY-MM-DD hh:mm A")}</p> */}
            <p className="ticket-data-bold">{date}</p>
          </div>
          <div className="ticketdata-container">
            <h4>Time</h4>
            {/* <p className="ticket-data-bold">{time12}</p> */}
            <p className="ticket-data-bold">
              <TimeFormat time={time} />
            </p>
          </div>

          <div className="ticketdata-container">
            <h4>Seats</h4>
            <p className="ticket-data-bold">{seats?.join(" , ")}</p>
          </div>

          <div className="ticketdata-container">
            <h4>Travel</h4>
            <p>{travelName}</p>
          </div>

          <div className="ticketdata-container">
            <h4>Route</h4>
            <p>{route}</p>
          </div>

          <div className="ticketdata-container">
            <h4>Name</h4>
            <p>{name}</p>
          </div>

          <div className="ticketdata-container">
            <h4>Phone No.</h4>
            <p>{phoneNo}</p>
          </div>

          <div className="ticketdata-container">
            <h4>Status</h4>
            <p>{status}</p>
          </div>
          <hr />
        </div>

        {/* <ReactToPrint
        trigger={() => ( */}
        {/* )}
        content={() => componentRef}
        documentTitle={`${showCaseTitle}-${seats}`}
      /> */}
        {/* <a href={() => componentRef} download="Example-PDF-document" target="_blank" rel="noreferrer">
        <button className="down-btn">
        <HiDownload size={"18"} className={"down-icon"} />
        Download Ticket
        </button>
      </a> */}
      </div>
      <button className="down-btn" onClick={() => print(travelName, "printDiv")}>
        <HiDownload size={"18"} className={"down-icon"} />
        Download Ticket
      </button>
    </div>
  );
}

export default TicketShowcase;
