/*eslint-disable*/
import { Box } from "@mui/material";
import React from "react";
import Services from "../components/local/servicesPage";

const ServicesPage = () => {
  return (
    <Box>
      <Services />
    </Box>
  );
};

export default ServicesPage;
