/* eslint-disable */

import React from "react";
import { useForm } from "react-hook-form";
import { FiLock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
// import useYupValidationResolver from "../../../../hooks/useYupValidationResolver";
// import FilledButton from "../../../form/CustomButton/FilledButton";
// import CustomInput from "../../../form/CustomInput/CustomInput";
import { resetVerifyPassword } from "./redux/actions";
// import SectionTitle from "./SectionTitle";
import { CircularProgress, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useYupValidationResolver from "../../../hooks/useYupValidationResolver";
import { getQuery } from "../../../utils/Helper";
import FilledButton from "../../form/CustomButton/FilledButton";
import CustomInput from "../../form/CustomInput/CustomInput";
import SectionTitle from "../home/SectionTitle";

function ResetPassword() {
  const profileState = useSelector((state) => state.profile);
  // const { passwordStatus } = useSelector((state) => state.profile);
  const { reset_password_loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = yup.object({
    // password: yup.string().required("New password is required"),
    password: yup
      .string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "The new password and confirm password must match.")
      .required("Confirm password is required")
  });
  const resolver = useYupValidationResolver(schema);

  const refetch = () => {
    navigate(`/`);
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver
  });

  const handlePasswordChange = (data) => {
    const finalData = {
      password: data?.password,
      password_confirmation: data?.password_confirmation,
      token: getQuery("token"),
      user_id: getQuery("user_id")
    };
    dispatch(resetVerifyPassword(finalData, refetch));
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "7rem" }}>
      <div className="password-form-container">
        <SectionTitle
          title={"Reset Password"}
          component={
            <FiLock color="#065F46" size={"16px"} fontWeight={700} className="profile-icons" />
          }
        />

        <form onSubmit={handleSubmit(handlePasswordChange)}>
          <CustomInput
            control={control}
            name={"password"}
            label={"New Password"}
            placeholder={"********"}
            type={"password"}
            errors={errors}
          />

          <CustomInput
            control={control}
            name={"password_confirmation"}
            label={"Confirm Password"}
            placeholder={"*******"}
            type={"password"}
            errors={errors}
          />

          <div className="btn-wrapper" style={{ marginTop: "18px" }}>
            <FilledButton
              btnLabel={
                reset_password_loading ? (
                  <CircularProgress
                    size="27px"
                    style={{ color: "#fff", display: "flex", alignItems: "center", margin: "auto" }}
                  />
                ) : (
                  "Update"
                )
              }
            />
          </div>
        </form>
      </div>
    </Container>
  );
}

export default ResetPassword;
