import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useYupValidationResolver from "../../../hooks/useYupValidationResolver";
import CustomInput from "../../form/CustomInput/CustomInput";
import { login, socialLogin } from "./redux/actions";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FacebookButton, GoogleButton } from "./SocialLoginButton";
// import { GoogleButton} from "./SocialLoginButton";

function Login({ handleOtherClose, handleClose, forgotToggle }) {
  const dispatch = useDispatch();

  const { siteSettingData } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);
  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);

  const { login_loading } = useSelector((state) => state.auth);

  const defaultValues = {
    email: "",
    password: ""
  };

  const schema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required.")
  });

  const resolver = useYupValidationResolver(schema);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues, resolver });

  const submitHandler = (values) => {
    dispatch(login(values, handleClose));
  };

  const handleRegister = () => {
    handleClose();
    handleOtherClose();
  };

  const handleForgot = () => {
    handleClose();
    forgotToggle();
  };

  const handleSocialLogin = (response, platform) => {
    handleClose();
    const accessToken =
      platform === "google"
        ? response.access_token
        : platform === "facebook"
        ? response.accessToken
        : undefined;
    dispatch(socialLogin(accessToken, platform, handleClose));
  };

  const handleSocialLoginError = (error) => {
    handleClose();
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: (codeResponse) => handleSocialLogin(codeResponse, "google"),
    onError: (error) => handleSocialLoginError(error)
  });

  return (
    <div className="login-section">
      <div className="image-container">
        <div className="img-wrapper">
          <img src={siteSettingInfo?.logo} alt="Logo" />
        </div>
        <div className="main-title">Login</div>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="mb-2">
            <CustomInput control={control} name="email" label="Email" errors={errors} />
          </div>
          <div className="mb-2">
            <CustomInput
              control={control}
              name="password"
              type="password"
              label="Password"
              errors={errors}
            />
          </div>
          {!login_loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ width: "100%" }} type="submit">
                Login
              </button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ width: "100%" }} type="button" disabled={true}>
                <div className="spinner-border spinner-border-sm text-white" role="status"></div>{" "}
                Login
              </button>
            </div>
          )}
          <div className="divider">
            <div></div>
            <p>Or continue with</p>
            <div></div>
          </div>

          <GoogleButton googleLoginHandler={googleLoginHandler} />
          <FacebookButton handleSocialLogin={handleSocialLogin} />

          <div className="last-line">
            Create new account? <span onClick={handleRegister}>Register</span> |{" "}
            <div className="forgot-password" onClick={handleForgot}>
              Forgot Password
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
