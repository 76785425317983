import * as actions from "../../../../components/local/contactUs/redux/types";
const initialState = {
  contactus: [],
  contactus_loading: false
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_CONTACT_US_BEGIN:
      return { ...state, contactus_loading: true };

    case actions.POST_CONTACT_US_SUCCESS:
      return { ...state, contactus_loading: false, contactus: action.payload };

    case actions.POST_CONTACT_US_ERROR:
      return { ...state, contactus_loading: false };
    default:
      return state;
  }
};

export default contactReducer ;
