import React from "react";

function TravelDetail({ route, date, seats, travel, bus_type, bus_no }) {
  const SEATSINFO = seats?.map((seat) => {
    return seat?.seat_name;
  });

  return (
    <div className="summary-container">
      <h4 className="travel-title">Travel Details</h4>
      <div className="travel-details-container">
        <div className="details-container">
          <span>Route:</span>
          <span>
            {route?.from?.city} - {route?.to?.city}
          </span>
        </div>

        <div className="details-container">
          <span>Date:</span>
          <span>{date}</span>
        </div>

        <div className="details-container">
          <span>Bus Type:</span>
          <span>{bus_type}</span>
        </div>
        <div className="details-container">
          <span>Bus No:</span>
          <span>{bus_no}</span>
        </div>
        <div className="details-container">
          <span>Seats:</span>
          <span>{SEATSINFO?.join(",")}</span>
        </div>

        <div className="details-container">
          <span>Travel:</span>
          <span>{travel}</span>
        </div>
      </div>
    </div>
  );
}

export default TravelDetail;
