import { CircularProgress } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { FiLock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import useYupValidationResolver from "../../../../hooks/useYupValidationResolver";
import FilledButton from "../../../form/CustomButton/FilledButton";
import CustomInput from "../../../form/CustomInput/CustomInput";
import SectionTitle from "./SectionTitle";
import { changePassword } from "./redux/actions";

function ProfilePasswordForm() {
  const profileState = useSelector((state) => state.profile);
  const { passwordStatus } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const schema = yup.object({
    oldpassword: yup.string().required("Old password is required"),
    newpassword: yup.string().required("New password is required"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("newpassword"), null], "The new password and confirm password must match.")
      .required("Confirm password is required")
  });
  const resolver = useYupValidationResolver(schema);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver
  });

  const handlePasswordChange = (data) => {
    dispatch(
      changePassword({
        old_password: data?.oldpassword,
        new_password: data?.newpassword,
        new_password_confirmation: data?.confirmpassword
      })
    );
  };

  return (
    <div className="password-form-container">
      <SectionTitle
        title={"Password"}
        component={
          <FiLock color="#065F46" size={"16px"} fontWeight={700} className="profile-icons" />
        }
      />

      <form onSubmit={handleSubmit(handlePasswordChange)}>
        <CustomInput
          control={control}
          name={"oldpassword"}
          label={"Old Password"}
          placeholder={"******"}
          type={"password"}
          errors={errors}
        />

        <CustomInput
          control={control}
          name={"newpassword"}
          label={"New Password"}
          placeholder={"********"}
          type={"password"}
          errors={errors}
        />

        <CustomInput
          control={control}
          name={"confirmpassword"}
          label={"Confirm Password"}
          placeholder={"*******"}
          type={"password"}
          errors={errors}
        />

        <div className="btn-wrapper">
          <FilledButton
            btnLabel={
              passwordStatus === "loading" ? (
                <CircularProgress
                  size="27px"
                  style={{ color: "#fff", display: "flex", alignItems: "center", margin: "auto" }}
                />
              ) : (
                "Update"
              )
            }
          />
        </div>
      </form>
    </div>
  );
}

export default ProfilePasswordForm;
