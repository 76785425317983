/* eslint-disable */
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    scrollTo(0, 0);
  }, [pathname]);

  const { siteSettingData, siteSetting_loading } = useSelector((state) => state.siteSetting);
  const [siteSettingInfo, setSiteSettingInfo] = useState([]);

  useEffect(() => {
    siteSettingData?.data && setSiteSettingInfo(siteSettingData?.data);
  }, [siteSettingData?.data]);

  return (
    <>
      <Box className="profile-container-wrapper">
        <h1>Privacy Policy</h1>
      </Box>

      {siteSetting_loading ? (
        <Box className="container">
          <Skeleton sx={{ margin: "-30px 0" }} height={500} width="100%" />
        </Box>
      ) : (
        <Box className="container">
          {/* <Box sx={{ margin: "40px 0" }}>
            <TermsAndConditionsDesc description={siteSettingInfo?.privacy_policy} />
          </Box> */}
          <div style={{ padding: "40px" }}>
            <p
              dangerouslySetInnerHTML={{
                __html: siteSettingInfo?.privacy_policy
              }}></p>
          </div>
        </Box>
      )}
    </>
  );
};

export default PrivacyPolicy;
