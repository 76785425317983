import React from "react";
import Home from "../components/local/home/Home";

function HomePage() {
  return (
    <>
      <Home />
    </>
  );
}

export default HomePage;
