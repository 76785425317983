/* eslint-disable */
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import OutlineBtn from "../../form/CustomButton/OutlineBtn";
import { changeStatusAfterTimeOut } from "../search/redux/actions";
// import { useState } from "react";

function PaymentDetails({ perTicketCost, totalCost, endDate, length, discount }) {
  const [clock, setClock] = useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    let current_date = moment(new Date());
    let end_date = moment(new Date(endDate?.[0]?.end_time));

    let diff = end_date.diff(current_date, "second");
    setClock(Math.max(diff, 0));

    let interval = setInterval(() => {
      setClock((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endDate?.[0]?.end_time]);

  useEffect(() => {
    if (clock === 0) {
      localStorage.removeItem("shadowEye");

      dispatch(
        changeStatusAfterTimeOut({ schedule_id: state?.schedule_id, seat_id: state?.seat_id })
      );

      Navigate("/search", { replace: true });
    }
  }, [clock]);

  const [percentage, setPercentage] = useState(null);

  // const num1 = 2000;
  // const num2 = 98;

  useEffect(() => {
    const calculatePricePercentage = () => {
      if (discount !== 0 || discount !== null) {
        const calculateDifference = (discount / perTicketCost) * 100;
        setPercentage(calculateDifference.toFixed(2));
      }
    };
    calculatePricePercentage();
  }, [discount, perTicketCost]);

  const netAmount =
    discount !== 0 || discount !== null
      ? length * perTicketCost - length * discount
      : length * perTicketCost;

  return (
    <div className="summary-container">
      <h4 className="travel-title">Payment Details</h4>
      <div className="payment-details-container">
        <div className="details-container" style={{ marginBottom: "10px" }}>
          <h3 style={{ marginBottom: "0px", width: "115px" }}>Per Ticket Cost:</h3>
          <span>Rs {perTicketCost}</span>
        </div>
        <div className="details-container" style={{ marginBottom: "10px" }}>
          <div>
            {/* <h3 style={{ display: 'flex', justifyContent: 'start' }}>Amount:</h3> */}
            {discount ? (
              <div
                style={{
                  fontSize: "13px",
                  color: "#064E3B",
                  fontWeight: 800,
                  padding: "5px",
                  marginTop: "-19px",
                  width: "115px"
                }}>
                Amount:
              </div>
            ) : (
              <div style={{ fontSize: "13px", color: "#064E3B", fontWeight: 800, padding: "5px" }}>
                Amount:
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <span>Rs {netAmount}</span>
            </div>
            {discount && (
              <div style={{ marginTop: "-6px" }}>
                <span
                  style={{
                    textDecoration: "line-through #f26f74",
                    color: "#f26f74",
                    fontSize: "13px"
                  }}>
                  Rs {length * perTicketCost}
                </span>
                <span style={{ marginLeft: "5px", fontSize: "13px" }}>{percentage}% off</span>
              </div>
            )}
          </div>
        </div>
        {/* <div className="details-container" style={{ marginBottom: "10px" }}>
          <h3 style={{ marginBottom: "0px" }}>Net Amount:</h3>
          <span>Rs {totalCost}</span>
        </div> */}
      </div>
      <div className="btn-container">
        <h4 className="travel-title" style={{ paddingBottom: "0px" }}>
          Remaining Time
        </h4>
        <OutlineBtn btnLabel={`${clock} seconds Remaining`} />
      </div>
    </div>
  );
}

export default PaymentDetails;
