// import { Box} from "@mui/material";
import React from "react";

const BannerSection = ({ data }) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <img src={data?.image} alt="item" className="heroImg" />
      </div>
    </>
  );
};

export default BannerSection;
