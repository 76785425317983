// import axios from "axios";
import { axiosInstance } from "../../apis/_axios";

// get locations
export const getLocationApi = () => {
  return axiosInstance().get(`/location`);
};

// get buses
export const searchBusApi = (data) => {
  return axiosInstance().get(`/search-bus`, { params: { ...data } });
};

// get seats information of individual Buses

export const getBusSeatsApi = (data) => {
  return axiosInstance().get(`/get-bus-seats/${data}`);
};

export const getSeatsSubmitApi = (data) => {
  return axiosInstance().get(`/pre-booking${data}`);
};

export const patchSeatsAfterTimeOutApi = (data) => {
  return axiosInstance().post(`/pre-booking-timeout`, data);
};

export const postCompleteBookingApi = (data) => {
  return axiosInstance().post("/bus-booking", data);
};

// export const getMyBookingApi = (data) => {
//   return axiosInstance().get("/my-booking", data);
// };

export const getMyBookingApi = (from_date, to_date) => {
  return axiosInstance().get("/my-booking", {
    params: { from_date: from_date, to_date: to_date }
  });
};

export const payThroughKhaltiApi = (data) => {
  return axiosInstance().post("/payment/khalti/initiate", data);
};

// popular destinations

export const getLocationMainApi = () => {
  return axiosInstance().get(`/location/main`);
};
