import {
  changePasswordApi,
  getUserDetailApi,
  updateUserDetailApi
} from "../../../../../apis/profile";
import { errorToast, successToast } from "../../../../../utils/toast";
import * as actions from "./types";

export const getUserDetail = () => (dispatch) => {
  dispatch({ type: actions.GET_USER_DETAIL_BEGIN });
  getUserDetailApi()
    .then((response) => {
      dispatch({ type: actions.GET_USER_DETAIL_SUCCESS, payload: response.data.data });
    })
    .catch((error) => {
      dispatch({ type: actions.GET_USER_DETAIL_ERROR });
    });
};

export const updateUserDetail =
  (data, refetch = () => {}) =>
  (dispatch) => {
    dispatch({ type: actions.UPDATE_USER_DETAIL_BEGIN });
    updateUserDetailApi(data)
      .then((response) => {
        dispatch({ type: actions.UPDATE_USER_DETAIL_SUCCESS, payload: response?.data?.data });
        successToast(response.data.message);
        refetch && refetch();
      })
      .catch((error) => {
        dispatch({ type: actions.UPDATE_USER_DETAIL_ERROR });
        errorToast(error);
      });
  };

export const changePassword = (data) => (dispatch) => {
  dispatch({ type: actions.CHANGE_PASSWORD_BEGIN });
  changePasswordApi(data)
    .then((response) => {
      dispatch({ type: actions.CHANGE_PASSWORD_SUCCESS });
      successToast(response?.data?.message);
    })
    .catch((error) => {
      dispatch({ type: actions.CHANGE_PASSWORD_ERROR });
      errorToast(error);
    });
};
