import React from "react";
import Verification from "../../components/local/auth/verifiedUser";

const VerificationPage = () => {
  return (
    <>
      <Verification />
    </>
  );
};

export default VerificationPage;
