/* eslint-disable */

import { Box, Button, Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQuery } from "../../../../utils/Helper";
import { verification } from "../redux/actions";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { verifiedLoading, verificationStatus } = useSelector((state) => state.auth);

  const handleNavigate = () => {
    navigate(`/`);
  };

  const data = {
    user_id: getQuery("user_id"),
    token: getQuery("token")
  };

  useEffect(() => {
    // if (data?.token && data?.user_id) {
    dispatch(verification(data));
    // }
  }, [data?.token, data?.user_id]);
  console.log("verificationStatus", verificationStatus);

  return (
    <Container maxWidth="lg">
      <Box sx={{ height: "50vh" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Box>
            {verificationStatus === "pending" ? (
              <h1>Verifying your email...</h1>
            ) : (
              <>
                {verificationStatus === "completed" ? (
                  <>
                    <h1>Email verified successfully.</h1>
                    <Button
                      onClick={handleNavigate}
                      sx={{
                        marginBottom: "1rem",
                        backgroundColor: "#047857  !important",
                        color: "#fff !important"
                      }}>
                      Go to homepage
                    </Button>
                  </>
                ) : (
                  <>
                    <h1>Email could not be verified.</h1>
                    <Button
                      onClick={handleNavigate}
                      sx={{
                        marginBottom: "1rem",
                        backgroundColor: "#047857  !important",
                        color: "#fff !important"
                      }}>
                      Go to homepage
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Verification;
