import { axiosInstance } from "../../apis/_axios";

export const postVerifyEsewaPaymentApi = (data) => {
  return axiosInstance().post("/payment/esewa/success", data);
};

export const postTransactionEsewaFailedApi = (data) => {
  return axiosInstance().post("/payment/esewa/fail", data);
};

export const postVerifyKhaltiPaymentApi = (data) => {
  return axiosInstance().post("/payment/khalti/success", data);
};

export const postTransactionKhaltiFailedApi = (data) => {
  return axiosInstance().post("/payment/khalti/fail", data);
};
